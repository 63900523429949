<template>
  <div class="menu-bar">
    <transition name="slide-up">
			<div class="menu-wrapper" :class="{'hide-box-shadow':ifSettingShow || !ifTitleAndMenuShow}" v-show="ifTitleAndMenuShow">
				<div class="icon-wrapper">
					<span class="icon-menu icon" @click="showSetting(3)"></span>
          <span class="menu-text">目录</span>
				</div>
				<div class="icon-wrapper">
				<span class="icon-progress icon" @click="showSetting(2)"></span>
          <span class="menu-text">进度</span>
				</div>
				<div class="icon-wrapper">
				<span class="icon-bright icon" @click="showSetting(1)"></span>
          <span class="menu-text">模式</span>
				</div>
				<div class="icon-wrapper">
					<span class="icon-a icon" @click="showSetting(0)">A</span>
          <span class="menu-text">字号</span>
				</div>
			</div>
    </transition>
    <transition name="slide-up">
      <div class="setting-wrapper" v-show="ifSettingShow">
        <div class="setting-font-size" v-if="showTag === 0">
          <div class="preview flex-end" :style="{fontSize:fontSizeList[0].fontSize + 'px'}">
            A
          </div>
          <div class="select">
            <input class="progress"
              type="range"
              max="100"
              min = "0"
              step = "20"
              @change="onFontSliderChange($event.target.value)"
              :value="fontSizeSliderValue" />
            <div class="select-wrapper" v-for="(item,index) in fontSizeList" :key="index"></div>
          </div>
          <div class="preview flex-start" :style="{fontSize:fontSizeList[fontSizeList.length - 1].fontSize + 'px'}">
            A
          </div>
        </div>
        <div class="setting-theme" v-else-if="showTag === 1">
          <div class="setting-theme-item" v-for="(item,index) in themeList" :key="index" @click="setTheme(index)">
            <div class="preview" :style="{background:item.style.body.background}" :class="{'no-border':item.style.body.background!=='#fff'}"></div>
            <div class="text" :class="{'selected':index === defaultTheme}">{{ item.name }}</div>
          </div>
        </div>
        <div class="setting-progress" v-else-if="showTag === 2">
          <div class="progress-wrapper">
            <input class="progress"
            type="range"
            max="100"
            min = "0"
            step = "1"
            @change="onProgressChange($event.target.value)"
            @input="onProgressInput($event.target.value)"
            :value="progress"
            :disabled="!bookAvailable"
            ref="progress">
          </div>
          <div class="text-wrapper">
            <span>{{bookAvailable ? progress + '%' : '加载中...'}}</span>
          </div>
        </div>
			</div>
    </transition>
    <!-- 目录 -->
    <content-view 
			:ifShowContent="ifShowContent"
      v-show="ifShowContent"
      :navigation="navigation"
      :bookAvailable="bookAvailable"
      @jumpTo="jumpTo"
      :currentPosition="currentPosition">
    </content-view>
    <transition name="fade">
      <div class="content-mask" v-show="ifShowContent" @click="hideContent"></div>
    </transition>
  </div>
</template>
<script>
import ContentView from '@/components/Content'
export default {
  components:{
    ContentView
  },
  props:{
    ifTitleAndMenuShow :{
      type: Boolean,
      default: false
    },
    fontSizeList: Array,
    defaultFontSize: Number,
    themeList: Array,
    defaultTheme: Number,
    bookAvailable: {
      type: Boolean,
      default: false
    },
    navigation: Object,
    parentProgress: Number,
    currentPosition: Object,
  },
  data(){
    return{
      ifSettingShow: false,
      showTag: 0,
      progress: 0,
      ifShowContent: true
    }
  },
  watch:{
    defaultFontSize: {
      handler: function (value){
        this.fontSize = value
      },
      immediate:true
    },
    bookAvailable:{
      handler: function(){
        this.getCurrentLocation()
      }
    },
    parentProgress: {
      handler: function (value){
        this.progress = value
        if(this.bookAvailable && this.$refs.progress){
					this.$refs.progress.style.backgroundSize = `${ this.progress }% 100%`
        }
      },
      deep:true
    },
    ifTitleAndMenuShow: {
      handler: function (){
        this.$emit('menuBarStatus', this.ifTitleAndMenuShow)
      },
      immediate:true
    }
  },
  computed: {
    fontSizeSliderValue: function () {
      let curIndex = this.fontSizeList.findIndex(item => {
        return item.fontSize == this.defaultFontSize
      })
      return 20*curIndex
    }
  },
  methods:{
    getCurrentLocation(){
      this.$emit('getCurrentLocation')
    },
    hideContent(){
      this.ifShowContent = false
    },
    jumpTo(target){
      this.$emit('jumpTo',target)
    },
    // 拖动进度条触发事件
    onProgressInput(progress){
			this.progress = progress
			this.$refs.progress.style.backgroundSize = `${ this.progress }% 100%`
    },
    // 进度条松开后触发事件，根据进度条数值跳转到指定位置
    onProgressChange(progress){
      this.$emit('onProgressChange', progress)
    },
    onFontSliderChange(value) {
      let sizeItem = this.fontSizeList[parseInt(value/20)]
      // console.log(sizeItem.fontSize)
      this.setFontSize(sizeItem.fontSize)
    },
    setTheme(index){
      this.$emit('setTheme', index)
    },
    setFontSize(fontSize){
      this.$emit('setFontSize', fontSize)
    },
    showSetting(tag){
      this.showTag = tag
      if(this.showTag === 3){
        this.$parent.hideMenuBar()
        this.$parent.openDirectory()
        this.ifSettingShow = false
        this.ifShowContent = true
      }else{
        this.ifSettingShow = true
      }
    },
    hideSetting(){
      this.ifSettingShow = false
    }
  }
}
</script>

<style scoped lang='less'>
.menu-bar{
  .menu-wrapper{
    position: absolute;
    display: flex;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120px;
    z-index: 10;
    background: white;
    box-shadow: 0 -16px 16px rgba(0, 0, 0, .15);
    &.hide-box-shadow{
      box-shadow: none;
    }
    .icon-wrapper{
      flex: 1;
      display: flex;
      flex-direction: column;
			justify-content: center;
			align-items: center;
      .icon-a{
        font-size: 18PX;
      }
      .menu-text{
        font-size: 12PX;
        margin-top: 8px;
        color: #333;
      }
    }
  }
  
	.setting-wrapper{
    position: absolute;
    bottom: 98px;
    left:0;
    z-index: 11;
    width: 100%;
    height: 120px;
    background:white;
    box-shadow: 0 -20px 20px rgba(0,0,0,.15);
    .setting-font-size{
      display: flex;
      height: 100%;
      .preview{
        flex: 0 0 80px;
        display: flex;
				justify-content: center;
				align-items: center;
      }
      .flex-start{
        justify-content: flex-start;
      }
      .flex-end{
        justify-content: flex-end;
      }
      .select{
        display: flex;
        flex: 1;
        margin: 0 40px;
        position: relative;
        justify-content: space-between;
        align-items: center; 
        .progress{
          position: absolute;
          left: 0;
          right: 0;
          width:100%;
          -webkit-appearance: none;
          height: 2px;
          background: -webkit-linear-gradient(#999, #999) no-repeat, #ddd;
          background-size: 0 100%;
          &:focus{
            outline:none;
          }
          &::-webkit-slider-thumb{
            -webkit-appearance:none;
            height:20px;
            width:20px;
            border-radius:50%;
            background:white;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .15);
            border:1px solid #ddd;
            position: relative;
          }
        }
        .select-wrapper{
          width: 1px;
          height: 13px;
          background-color: #ddd;
        }
      }
    }

    .setting-theme{
      height:100%;
      display:flex;
      .setting-theme-item{
        flex:1;
        display:flex;
        flex-direction:column;
        padding: 15px;
        box-sizing:border-box;
        .preview{
          flex:1;
          border:1px solid #ccc;
          box-sizing:border-box;
          &.no-border{
            border:none
          }
        }

        .text{
          flex:0 0 20px;
          margin-top: 3px;
          font-size: 12PX;
          color:#ccc;
          display: flex;
					justify-content: center;
					align-items: center;
          &.selected{
            color:#333;
          }
        }
      }
    }

    .setting-progress{
      position:relative;
      width:100%;
      height:100%;
      .progress-wrapper{
        width:100%;
        height:100%;
        display: flex;
				justify-content: center;
				align-items: center;
        padding: 0 60px;
        box-sizing: border-box;
        .progress{
          width:100%;
          -webkit-appearance: none;
          height: 2px;
          background: -webkit-linear-gradient(#999, #999) no-repeat, #ddd;
          background-size: 0 100%;
          &:focus{
            outline:none;
          }
          &::-webkit-slider-thumb{
            -webkit-appearance:none;
            height:20px;
            width:20px;
            border-radius:50%;
            background:white;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .15);
            border:1px solid #ddd;
          }
        }
      }
      .text-wrapper{
        position: absolute;
        width: 100%;
        bottom: 5px;
        color: #333;
        font-size: 12PX;
        span{
          display: flex;
					justify-content: center;
					align-items: center;
        }
      }
    }
  }

  .content-mask{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(51,51,51,.8);
  }
}
</style>
