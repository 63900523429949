<template>
  <transition name="slide-right">
    <div class="toc-catalogue">
      <!-- <div class="catalogue-wrapper" v-if="bookAvailable"> -->
      <div class="catalogue-wrapper" ref="listContainer" v-if="navigation">
        <!-- 一级目录 -->
        <div 
          :ref="item.href"
          :class="['catalogue-item', firstHref == item.href ? 'on-doc1' : '']" 
          v-for="(item, index) in navigation.toc"
          :key="index"
          @click.stop="jumpTo(item.href)">
          <div class="item-row">
            <span class="text" v-waves>{{ item.label }}</span>
            <span
              v-if="item.isShowIcon"
              :class="['icon-arrow-icon', item.isShowIcon && !item.subListFlag ? 'down' : '', item.isShowIcon && item.subListFlag ? 'up' : '']"
              @click.stop="toggleFlag(item)"></span>
            <!-- <span class="icon-arrow-up" v-if="item.isShowIcon && item.subListFlag" @click.stop="toggleFlag(item)"></span>
            <span class="icon-arrow-down" v-if="item.isShowIcon && !item.subListFlag" @click.stop="toggleFlag(item)"></span> -->
          </div>
          <!-- 二级目录 -->
          <transition-group name="list" tag="div">
            <div v-if="item.subListFlag" key="list-animation">
              <div 
                :ref="item2.href"
                :class="['catalogue-item2', secondHref == item2.href ? 'on-doc2' : '']"
                v-for="(item2, index2) in item.subitems"
                :key="index2"
                @click.stop="jumpTo(item2.href)">
                <span class="text" v-waves>{{ item2.label }}</span>
                <!-- 三级目录 -->
                <!-- <div 
                  :ref="item3.href"
                  :class="['catalogue-item3', thirdHref == item3.href ? 'on-doc3' : '']" 
                  v-for="(item3, index3) in item2.subitems" 
                  :key="index3" 
                  @click.stop="jumpTo(item3.href)">
                  <span class="text" v-waves>{{ item3.label }}</span>
                </div> -->
              </div>
            </div>
          </transition-group>
        </div>

        <div class="continue-btn" @click="onCloseMl" v-show="continueRead">
          <span>继续阅读</span>
        </div>
      </div>
      <div class="empty" v-else>加载中...</div>
    </div>
  </transition>
</template>
<script>
import waves from '@/directives/waves/index.js'
export default {
  directives: { waves },
  props:{
    ifShowContent: Boolean,
    navigation:{
      type: Object,
      default() {
        return {
          toc: []
        }
      }
    },
    bookAvailable: {
      type: Boolean,
      default: true
    },
    currentPosition: Object,
  },
  data(){
    return{
      continueRead: false,
      firstHref: '',
      secondHref: '',
      thirdHref: '',
    }
  },
  watch: {
    currentPosition: {
      handler(value) {
        this.curHref = value.start.href
        if(this.navigation) {
          let doc = this.navigation.toc || []
          this.firstHref = '' // 当前一级目录
          this.secondHref = '' // 当前二级目录
          this.thirdHref = '' // 当前三级目录
          doc.forEach(item1 => {
            if(item1.isShowIcon) item1.subListFlag = false; // 收起子目录
            if(item1.href == this.curHref){
              this.firstHref = this.curHref
              // console.log(this.firstHref , this.secondHref ,this.thirdHref)
            }else{
              let sub2 = item1.subitems
              if(sub2.length) {
                sub2.forEach(item2 => {
                  if(item2.href == this.curHref){
                    item1.subListFlag = true // 展开一级目录
                    this.firstHref = item1.href
                    this.secondHref = item2.href
                    // console.log(this.firstHref , this.secondHref ,this.thirdHref)
                  }else{
                    let sub3 = item2.subitems
                    if(sub3.length) {
                      sub3.forEach(item3 => {
                        if(item3.href == this.curHref){
                          item1.subListFlag = true // 展开一级目录
                          this.firstHref = item1.href
                          this.secondHref = item2.href
                          this.thirdHref = item3.href
                          // console.log(this.firstHref , this.secondHref ,this.thirdHref)
                        }
                      })
                    }
                  }
                })
              }
            }
          })
          // 设置滚动
          if(this.firstHref && this.secondHref && this.thirdHref) {
            this.$nextTick(()=>{
              this.$refs[this.thirdHref][0].scrollIntoView();
              this.$refs.listContainer.scrollTo({
                top: this.$refs.listContainer.scrollTop - window.innerHeight/2,
                // behavior: 'smooth'
              })
            })
            
          }
          else if(this.firstHref && this.secondHref && !this.thirdHref){
            this.$nextTick(()=>{
              this.$refs[this.secondHref][0].scrollIntoView();
              this.$refs.listContainer.scrollTo({
                top: this.$refs.listContainer.scrollTop - window.innerHeight/2,
                // behavior: 'smooth'
              })
            })
          }
          else if(this.firstHref && !this.secondHref && !this.thirdHref){
            this.$refs[this.firstHref][0].scrollIntoView();
              this.$refs.listContainer.scrollTo({
                top: this.$refs.listContainer.scrollTop - window.innerHeight/2,
                // behavior: 'smooth'
              })
          }
        }
      },
      deep: true
    },
    ifShowContent(value) {
      if(value) this.continueRead = true
    }
  },
  methods:{
    toggleFlag(item) {
      // if(this.activeMenu) {
      //   if(this.activeMenu.id != item.id) {
      //     this.navigation.toc.forEach(el => {
      //       if(el.isShowIcon){
      //         el.subListFlag = false
      //       }
      //     })
      //   }
      // }
      item.subListFlag = !item.subListFlag
      // this.activeMenu = item
    },
    jumpTo(target){
      this.$emit('jumpTo', target)
    },
    onCloseMl() {
      this.$parent.ifShowContent = false
    }
  },
  mounted() {
    const DOWNLOAD_URL = '/乡村卫生健康实用手册.epub'
    let eBookConfigs = localStorage.getItem('E_BOOK_CONFIGS')
    if(eBookConfigs){
      eBookConfigs = JSON.parse(eBookConfigs)
      // 同一本书
      if(eBookConfigs.eBookName == DOWNLOAD_URL){
        // 阅读位置
        if(eBookConfigs.cfi) {
          this.continueRead = true
        }else {
          this.continueRead = false
        }
      }else{
        this.continueRead = false
      }
    }else{
      this.continueRead = false
    }
  }
}
</script>

<style scoped lang='less'>
.toc-catalogue{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  height: 100%;
  background: white;
  overflow: hidden;
  .catalogue-wrapper{
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .catalogue-item{
      border-bottom: 1px solid #f4f4f4;
      // border-bottom: 1px solid #f0f0f0;
      .item-row{
        display: flex;
        overflow: hidden;
      }
      .text{
        padding: 0 0 0 20px;
        display: inline-block;
        font-size: 16PX;
        line-height: 90px;
        color: #333;
        flex: 1;
      }
      .icon-arrow-icon{
        display: inline-block;
        width: 80px;
        height: 90px;
        background: url('~@/assets/images/gengduo.png') no-repeat center center;
        background-size: 20px 24px;
        transform-origin: center;
        transition: all .3s;
        &.down{
          transform: rotate(90deg);
        }
        &.up{
          transform: rotate(-90deg);
        }
      }
      .icon-arrow-up, .icon-arrow-down{
        display: inline-block;
        width: 70px;
        height: 90px;
        position: relative;
        margin-left: 30px;
        &:before{
          display: inline-block;
          content: "";
          border-style: solid;
          // border-width: 8px;
          border-left-color: transparent;
          border-right-color: transparent;
          // border-top-color: #777;
          border-width: 14px;
          border-top-color: #cd2b42;
          border-bottom-width: 0;
          border-bottom-color: transparent;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          transform-origin: center;
          transition: all .3s;
        }
      }
      .icon-arrow-up{
        &:before{
          // border-bottom-width: 8px;
          // border-top-color: #777;
          // border-bottom-width: 14px;
          // border-bottom-color: #cd2b42;
          // border-top-color: transparent;
          transform: translateX(-50%) translateY(-50%) rotate(180deg);
          transition: all .3s;
        }
      }
    }
    .on-doc1 .item-row .text{
      color: #cd2b42;
      font-weight: bold;
    }
    .catalogue-item2, .catalogue-item3{
      // border-bottom: 1px solid #f4f4f4;
      display: flex;
      flex-direction: column;
      .text{
        padding: 0 20px;
        display: inline-block;
        font-size: 15PX;
        line-height: 80px;
        color: #333;
      }
    }
    .catalogue-item2 > .text{
      padding: 0 20px 0 40px;
    }
    .catalogue-item3 > .text{
      padding: 0 20px 0 60px;
    }
    .on-doc2 > .text{
      color: #cd2b42;
      font-weight: bold;
      background-color: rgba(205, 43, 66, .05);
    }
    
    .continue-btn{
      color: #fff;
      font-size: 12PX;
      height: 150px;
      width: 145px;
      background-color: #cd2b42;
      position: fixed;
      top: 30px;
      right: -80px;
      // transform: translateX(-50%);
      border-radius: 50%;
      display: flex;
      align-items: center;
      box-shadow: 0 0 12px rgba(205, 43, 66, .6);
      span{
        writing-mode: vertical-rl;
        letter-spacing: 3px;
        position: absolute;
        left: 26px;
      }
    }
  }
  .empty{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16PX;
    color: #333;
  }

  .list-enter-active {
    transition: all .15s;
  }
  .list-enter{
    opacity: 0;
    transform: translateY(-20px);
  }
}
</style>
