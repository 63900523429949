<template>
  <div class="epub-container container">
    <!-- <title-bar :ifTitleAndMenuShow="ifTitleAndMenuShow"></title-bar> -->
    <div class="read-wrapper" ref="containerEle">
      <!-- 电子书容器 -->
      <div id="read"></div>
      <!-- 透明操作弹层 -->
      <div class="opration-mask" @touchstart="touchStart" @touchend="touchEnd"></div>
      <!-- 移动设备下--提示操作文案 -->
      <!-- <div class="tip-mask" v-if="tipsShow">
        <div class="tip-close" @click="closeTips" @touchend="closeTips">
          <div class="tip-row">
            <span class="direction-span">←</span>
            <span class="text-span">左右滑动可阅读</span>
            <span class="direction-span">→</span>
          </div>
        </div>
        <div class="close-tip-row">
          <span class="text-btn" @click.stop="closeTipsForever">不再提示</span>
        </div>
      </div> -->
      <!-- 返回目录按钮 -->
      <div 
        v-show="bookReady && ifShowBtn"
        class="back-ml" 
        :style="btnPosition" 
        ref="btnEle"
        @click="openDirectory"
        @touchstart.stop=listenBtnTouchstart
        @touchmove.stop.prevent="listenBtnTouchmove"
        @touchend.stop=listenBtnTouchend
        >
        <i></i><span>返回目录</span>
      </div>
    </div>
    <menu-bar 
      ref="menuBar"
      :ifTitleAndMenuShow="ifTitleAndMenuShow"
      @getCurrentLocation="getCurrentLocation"
      :fontSizeList = "fontSizeList"
      :defaultFontSize="defaultFontSize"
      @setFontSize="setFontSize"
      :themeList="themeList"
      :defaultTheme="defaultTheme"
      @setTheme = "setTheme"
      :bookAvailable = "bookAvailable"
      @onProgressChange = "onProgressChange"
      @jumpTo="jumpTo"
      :navigation = "navigation"
      :parentProgress = "progress"
      :currentPosition="currentPosition"
      @menuBarStatus="getMenuBarStatus">
    </menu-bar>
  </div>
</template>

<script>
// import { isIos } from '@/util/util'
// import TitleBar from '@/components/TitleBar'
import MenuBar from '@/components/MenuBar'
import Epub from 'epubjs'
// import {EpubCFI} from 'epubjs'
const DOWNLOAD_URL = '/乡村卫生健康实用手册.epub'

export default {
  name: 'EpubBook',
  components: {
    // TitleBar,
    MenuBar
  },
  data(){
    return {
      zjIndex: 0,
      ifTitleAndMenuShow: false,
      // 是否为pc端
      isPcDevices: true,
      // 提示文案
      tipsShow: false,
      fontSizeList:[
        {
          fontSize:12
        },
        {
          fontSize:14
        },
        {
          fontSize:16
        },
        {
          fontSize:18
        },
        {
          fontSize:20
        },
        {
          fontSize:22
        }
      ],
      defaultFontSize: 18,
      themeList:[
        {
          name:'默认',
          style:{
            body:{
              'color':'#000',
              'background':'#fff'
            }
          }
        },
        {
          name:'护眼',
          style:{
            body:{
              'color':'#000',
              'background':'#ceeaba'
            }
          }
        },
        {
          name:'夜间',
          style:{
            body:{
              'color':'#fff',
              'background':'#444'
            }
          }
        },
        {
          name:'古黄',
          style:{
            body:{
              'color':'#000',
              'background': 'rgb(238, 232, 170)'//241.236,226
            }
          }
        }
      ],
      defaultTheme: 0,
      bookAvailable: false,
      navigation: null,
      progress: 0, // 阅读进度
      // 按钮位置
      btnPosition: {
        right: 0,
        bottom: '20px'
      },
      bookReady: false,
      ifShowBtn: true,
      currentPosition: null
    }
  },
  methods:{
    // 展示当前阅读位置
    getCurrentLocation(){
      if(this.rendition) this.showProgress();
    },
    // 进度条位置状态更新
    showProgress(){
      if(this.rendition.currentLocation().start) {
        // 获取当前位置信息
        const currentLoction = this.rendition.currentLocation().start.cfi
        // 获取当前位置进度百分比
        this.progress = this.bookAvailable ? this.locations.percentageFromCfi(currentLoction) : 0
        // 转化成0-100的数字
        this.progress = Math.round(this.progress*100)
        // 保存阅读位置
        this.saveConfigs({
          currentLoction: true
        })
      }
    },
    // 根据目录链接跳转到指定位置
    jumpTo(href){
      this.rendition.display(href).then(()=>{
        this.showProgress()
      })
      this.hideTitleAndMenu()
    },
    hideTitleAndMenu(){
      // 隐藏标题栏和菜单栏
      this.ifTitleAndMenuShow = false
      // 隐藏菜单栏弹出的设置栏
      this.$refs.menuBar.hideSetting()
      // 隐藏目录
      this.$refs.menuBar.hideContent()
    },
    // 根据进度条变化跳转更新内容
    onProgressChange(progress){
      const percentage = progress / 100
      const location = percentage > 0 ? this.locations.cfiFromPercentage(percentage) : 0
      this.rendition.display(location)
      // 保存阅读位置
      this.saveConfigs({
        currentLoction: true
      })
    },
    // 保存当前阅读进度（currentLoction）、字号（defaultFontSize）、主题色（themeIndex）
    saveConfigs(configs) {
      let eBookConfigs = localStorage.getItem('E_BOOK_CONFIGS')
      if(eBookConfigs) {
        eBookConfigs = JSON.parse(eBookConfigs)
      }else{
        eBookConfigs = {
          eBookName: DOWNLOAD_URL,
        }
      }
      // 阅读位置
      if(configs.currentLoction){
        // 获取当前页面的cfi
        let currentPosition = this.rendition.currentLocation()
        const cfibase = currentPosition.start.cfi.replace(/!.*/, '')
        const cfistart = currentPosition.start.cfi.replace(/.*!/, '').replace(/\)$/, '')
        const cfiend = currentPosition.end.cfi.replace(/.*!/, '')
        const cfirange = `${cfibase}!,${cfistart},${cfiend}`
        // console.log(cfirange)
        eBookConfigs.cfi = cfirange;
        // 获取当前页面的文本信息
        // this.book.getRange(cfirange).then(range => {
        //   console.log(range.toString())
        // })
      } 
      // 字号
      if(configs.defaultFontSize) eBookConfigs.fontSize = configs.defaultFontSize;
      // 主题色
      if(this.themeList[configs.themeIndex]) eBookConfigs.themeIndex = configs.themeIndex;

      let objLens = Object.keys(eBookConfigs);
      if(objLens) localStorage.setItem('E_BOOK_CONFIGS', JSON.stringify(eBookConfigs));
    }, 
    // 设置主题样式
    setTheme(index){
      // this.themes.select(this.themeList[index].name);
      this.defaultTheme = index;
      // 保存主题样式设置
      this.saveConfigs({
        themeIndex: index
      })
      this.themes.select(this.themeList[index].name)      
      const bodyObject = this.themeList[index].style.body      
      for(let key in bodyObject){        
        this.rendition.themes.override(key, bodyObject[key], true)      
      }
    },
    registerTheme(){
      this.themeList.forEach(theme=>{
        this.themes.register(theme.name, theme.style)
      })
    },
    // 设置字号
    setFontSize(fontSize){
      // console.log(fontSize)
      this.defaultFontSize = fontSize
      if(this.themes){
        this.themes.fontSize(fontSize + 'px')
        this.themes.override("font-size", this.defaultFontSize)
      }
      // 保存字号设置
      this.saveConfigs({
        defaultFontSize: fontSize
      })
    },
    // 展示/隐藏头部、底部工具栏
    toggleTitleAndMenu(){
      this.ifTitleAndMenuShow = !this.ifTitleAndMenuShow
      if(!this.ifTitleAndMenuShow){
        this.$refs.menuBar.hideSetting()
      }
    },
    // 隐藏底部工具栏
    hideMenuBar() {
      this.ifTitleAndMenuShow = false
      this.$refs.menuBar.hideSetting()
    },
    // 上一页
    prevPage(){
      // Rendition.prev
      if(this.rendition){
        // 延迟80ms翻页
        if(this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.rendition.prev().then(()=>{
            this.showProgress()
          })
        }, 80);
      }
      this.hideMenuBar()
    },
    // 下一页
    nextPage(){
      // Rendition.next
      if(this.rendition){
        // 延迟80ms翻页
        if(this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.rendition.next().then(()=>{
            this.showProgress()
          })
          // 章节长度
          let chapterLength = this.navigation.toc.length
          // 当前章节信息
          let endChapterEnd = this.rendition.currentLocation().end
          if(endChapterEnd && (chapterLength-1 == endChapterEnd.index)) {
            // 章节周后一页
            if(endChapterEnd.displayed.page == endChapterEnd.displayed.total){
              this.$toast('已是最后一页')
            }
          }
        }, 80);
      }
      this.hideMenuBar()

      // 去除高亮样式
      // console.log(this.searchResultList)
      // this.searchResultList.forEach((item) => {
      //   let cfiRange = item.cfi;
      //   this.rendition.annotations.remove(cfiRange,  "highlight");
      //   this.rendition.annotations.remove(cfiRange,  "underline");
      // })
    },
    // 电子书的解析和渲染
    showEpub(){
      this.loadingToast = this.$toast({
        type: 'loading',
        message: '加载中...',
        forbidClick: true,
        duration: 300000
      });
      
      // 生成book,Rendition,
      // this.book = new Epub(DOWNLOAD_URL, {
      //   openAs: "epub"
      // })
      this.book = new Epub(DOWNLOAD_URL)
      this.rendition = this.book.renderTo('read',{
        width: window.innerWidth,
        height: window.innerHeight,
        method: 'default',
        snap: true,  // 预加载
        flow: "paginated", // auto || paginated || scrolled-doc
        stylesheet: '/epub-reset.css',
      })
        
      // 通过Rendition.display渲染电子书，并重新赋值阅读进度
      let eBookConfigs = localStorage.getItem('E_BOOK_CONFIGS')
      if(eBookConfigs){
        eBookConfigs = JSON.parse(eBookConfigs)
        // 同一本书
        if(eBookConfigs.eBookName == DOWNLOAD_URL){
        // if(eBookConfigs.eBookName == this.tetsMl[this.zjIndex].testPath){
          // 阅读位置
          if(eBookConfigs.cfi) {
            // console.log(eBookConfigs.cfi)
            this.rendition.display(eBookConfigs.cfi)
          }else {
            this.rendition.display()
          }
          // 字号
          if(eBookConfigs.fontSize) {
            this.defaultFontSize = eBookConfigs.fontSize
            // console.log(eBookConfigs)
          }
          // 主题
          if(eBookConfigs.themeIndex) {
            this.defaultTheme = eBookConfigs.themeIndex
          }
        }else{
          localStorage.removeItem("E_BOOK_CONFIGS");
          this.rendition.display()
        }
      }else{
        this.rendition.display()
      }

      // 获取Theme对象
      this.themes = this.rendition.themes
      // this.themes.register(name, styles)
      this.registerTheme()
      // this.themes.select(name)
      this.setTheme(this.defaultTheme)
      // 获取locations对象 epubjs的钩子函数实现
      this.book.ready.then(()=>{
        setTimeout(()=>{
          this.loadingToast.clear();
          this.bookReady = true
          this.$nextTick(() => {
            this.btnDom = this.$refs.btnEle
            this.btnWidth = this.$refs.btnEle.offsetWidth || 0
            this.btnHeight = this.$refs.btnEle.offsetHeight || 0
          })
        },1000)
        if(this.book.navigation.toc) {
          // console.log(this.book.navigation.toc)
          this.book.navigation.toc.forEach(item => {
            if(item.subitems && item.subitems.length){
              item.isShowIcon = true
              item.subListFlag = false
            }
          })
        }
        this.navigation = this.book.navigation
        // 全文查询关键字
        // this.doSearch('农村').then(results => {
        //   // console.log(results);
        //   this.searchResultList = results
        //   // console.log(this.rendition.getRange)
        //   this.searchResultList.forEach((item, index) => {
        //     let cfi = item.cfi;
        //     // this.rendition.annotations.add(cfiRange,'highlight')
        //     let range = new EpubCFI(cfi);
        //     // console.log(range)
        //     this.rendition.annotations.highlight(cfi, {
        //     // 属性  例如 name:"name" -> data-name='name'
        //     }, function() {
        //         // 点击事件回调
        //     }, 'className', {
        //         'fill': 'red',
        //         'color': 'yellow'
        //     });

        //     if(index == 10) {
        //       console.log(item)
        //       console.log(range)
        //       this.rendition.display(range.str)
        //     }
        //   })
        // })
        // console.log(this.navigation)
        return this.book.locations.generate()
      }).then(result=>{
        this.result = result
        this.locations = this.book.locations
        this.bookAvailable = true
      })

      // 监听手指
      // this.rendition.on('touchstart', e1 => {
      //   console.log(e1)
      // })
      // this.rendition.on('touchend', e2 => {
      //   console.log(e2)
      // })
    },
    // 检索全文
    doSearch(q) {
      return Promise.all(
        this.book.spine.spineItems.map(section => section.load(this.book.load
          .bind(this.book))
          .then(section.find.bind(section, q))
          .finally(section.unload.bind(section)))
         ).then(results => Promise.resolve([].concat.apply([], results))) // apply通过参数传递results中的值，达到降低维度的效果，一定要用空数组
    },
    touchStart(e) {
      // console.log(1, e)
      this.touchStartX = e.touches[0].clientX;
      this.touchStartY = e.touches[0].clientY;
    },
    touchEnd(e) {
      // console.log(2, e)
      let viewInnerWidth = window.innerWidth
      let deltaX = e.changedTouches[0].clientX - this.touchStartX;
			let deltaY = e.changedTouches[0].clientY - this.touchStartY;
      // 若Y轴的滑动 > 2倍X轴的滑动距离，不操作；
      // console.log(Math.abs(deltaY), Math.abs(deltaX)*2)
      if(Math.abs(deltaY) > Math.abs(deltaX)*2) {
        if(Math.round(deltaY)) {
          if(deltaY > 0) {
            // console.log("Yyy滑--上一页")
            this.prevPage()
          }else {
            // console.log("Yyy滑--下一页")
            this.nextPage()
          }
        }
      }
      // 若Y轴的滑动 <= X轴的滑动距离，判断x轴方向进行翻页；
      else {
        if(Math.round(deltaX)) {
          if (deltaX > 0) {
            // console.log("Xxxxx滑--上一页")
            this.prevPage()
          } else if(deltaX < 0) {
            // console.log("Xxxxx滑--下一页")
            this.nextPage()
          }
        }
        // 手指未发生偏移，即没有滑动
        else{
          // console.log("未滑--------")
          let cX = e.changedTouches[0].clientX
          // 点击左1/3屏幕
          if(cX < viewInnerWidth/3) {
            // console.log("Xxxxx点击--上一页")
            this.prevPage()
          }
          // 点击右1/3屏幕
          else if(cX > viewInnerWidth/3*2) {
            // console.log("Xxxxx点击--下一页")
            this.nextPage()
          }
          // 点击中间1/3屏幕
          else{
            this.toggleTitleAndMenu()
          }
        }
      }
    },
    closeTips() {
      this.tipsShow = false
    },
    closeTipsForever() {
      this.tipsShow = false
      localStorage.setItem("NO_TIPS", 1)
    },
    getMenuBarStatus(state) {
      if(state){
        this.ifShowBtn = false
      }else{
        this.ifShowBtn = true
      }
    },
    // 打开目录
    openDirectory() {
      this.currentPosition = this.rendition.currentLocation()
      this.$refs.menuBar.ifShowContent = true
    },
    listenBtnTouchstart(e) {
      let touchTarget = e.targetTouches[0]
      let curX = Math.round(touchTarget.clientX)
      let curY = Math.round(touchTarget.clientY)
      let btnLeft = Math.round(this.btnDom.offsetLeft) || 0
      let btnTop = Math.round(this.btnDom.offsetTop) || 0
      this.pointBtnLeft = curX - btnLeft // 触点距离按钮左边的距离
      this.pointBtnTop = curY - btnTop // 触点距离按钮上边的距离
    },
    listenBtnTouchmove(e) {
      let touchTarget = e.targetTouches[0]
      let curX = Math.round(touchTarget.clientX)
      let curY = Math.round(touchTarget.clientY)
      let pageWidth = this.pageWidth
      let pageHeight = this.pageHeight
      let btnWidth = this.btnWidth
      let btnHeight = this.btnHeight
      let distanceY = 20 // 20为按钮距离底部区域的间隙高度
      
      if(curX <= this.pointBtnLeft) { // 超出左边界
        console.log(curX, this.pointBtnLeft)
        this.btnDom.style.right = pageWidth - btnWidth + 'px'
      }
      else if(curX >= (pageWidth - (btnWidth - this.pointBtnLeft))) { // 超出右边界
        this.btnDom.style.right = 0
      }
      else{
        this.btnDom.style.right = pageWidth - curX - (btnWidth - this.pointBtnLeft) + 'px'
      }

      if(curY <= this.pointBtnTop) { // 超出上边界
        this.btnDom.style.bottom = pageHeight - btnHeight + 'px'
      }
      else if(curY >= (pageHeight - (btnHeight - this.pointBtnTop) - distanceY)) { // 超出下边界
        this.btnDom.style.bottom = distanceY + 'px'
      }
      else{
        this.btnDom.style.bottom = pageHeight - curY - (btnHeight - this.pointBtnTop) + 'px'
      }
    },
    listenBtnTouchend() {
      let pageWidth = this.pageWidth
      let btnWidth = this.btnWidth
      let btnLeft = Math.round(this.btnDom.offsetLeft) || 0
      let centerLeft = (pageWidth - btnWidth)/2 // 按钮在屏幕正中时，距离屏幕左边距
      if(btnLeft < centerLeft) { // 按钮中心在屏幕中线偏左
        this.btnDom.style.right = pageWidth - btnWidth + 'px' // 向左吸边
      }else{
        this.btnDom.style.right = 0 // 向右吸边
      }
    },
    // 判断是否为移动端
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
  },
  mounted(){
    this.showEpub()
    this.pageWidth = this.$refs.containerEle.offsetWidth || 0
    this.pageHeight = this.$refs.containerEle.offsetHeight || 0
    // 开启默认滑动翻页
    // if(this.openManager) {
    //   // 不再提示
    //   if(localStorage.getItem("NO_TIPS")) {
    //     this.tipsShow = false
    //   }else{
    //     this.tipsShow = true
    //     setTimeout(() => {
    //       this.tipsShow = false
    //     }, 5000)
    //   }
    // }
  },
}
</script>

<style lang="less">
.epub-container{
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 100%;
  .read-wrapper{
    width: 100%;
    height: 100%;
    #read{
      width: 100%;
      height: 100%;
    }
    .opration-mask{
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 7;
      .left{
        width: 33.3333%;
      }
      .center{
        width: 33.3333%;
      }
      .right{
        width: 33.3333%;
      }
    }
    .tip-mask{
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
      background-color: rgba(0, 0, 0, .5);
      overflow: hidden;
      .tip-close{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      .tip-row{
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-60%);
        color: #fff;
        font-size: 17PX;
        text-align: center;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: scaleAnimate 3s infinite;
        .text-span{
          margin: 0 20px;
          line-height: .8em;
        }
        .direction-span{
          font-size: 18PX;
          
        }
        @keyframes scaleAnimate {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.08);
          }
          100% {
            transform: scale(1);
          }
        }
      }
      .close-tip-row{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15%;
        color: #fff;
        font-size: 14PX;
        display: flex;
        justify-content: center;
        z-index: 10;
        .text-btn{
          border: 1px solid #fff;
          padding: 5px 10px;
          border-radius: 4px;
        }
      }
    }

    .back-ml{
      position: absolute;
      right: 0;
      bottom: 20px;
      background-color: #cd2b42;
      color: #fff;
      font-size: 13PX;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 24px;
      border-radius: 10px;
      z-index: 8;
      span{
        width: 4em;
      }
      i{
        display: inline-block;
        width: 12px;
        height: 12px;
        border-width: 1px;
        border-style: solid;
        border-top-color: #fff;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: #fff;
        margin-right: 6px;
        transform: rotate(-45deg);
        transform-origin: center;
      }
    }
  }
}
</style>

